import React from 'react';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {useSearchParams} from 'react-router-dom';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {useGetEmployeePreApprovalRequestQuery} from '@compt/app/services/api/employee-learning-development-slice';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {ComptLink} from '@compt/common/compt-link/compt-link';
import {getCustomFieldURLKey} from '@compt/types/learning-development/pre-approval-request';

interface ReimbursementSidePanelProps {
  open: boolean;
  companyId: number;
  'data-testid'?: string;
}

export const ReimbursementSidePanel = (props: ReimbursementSidePanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const preApprovalRequestId = searchParams.get('pre_approval_id');
  const preApprovalRequestQuery = useGetEmployeePreApprovalRequestQuery({
    companyId: props.companyId,
    requestId: preApprovalRequestId,
  });
  const preApprovalRequest = preApprovalRequestQuery.data;

  if (!preApprovalRequest) {
    return <></>;
  }

  function clearSelectedClaim() {
    setSearchParams({});
  }

  const preApprovalRequestReceiptUrl: string = (
    preApprovalRequest?.request_data[getCustomFieldURLKey('Upload document(s)')] || ''
  ).toString();

  return (
    <>
      <ComptSidePanel
        open={props.open}
        className="max-w-[464px]"
        data-testid={props['data-testid']}
      >
        <ComptSidePanel.Header
          title={preApprovalRequest.request_data['Vendor name']}
          setOpen={clearSelectedClaim}
          headerIcon={{id: 'file-icon-blue'}}
        />
        <ComptSidePanel.Content className="sm:overflow-y-hidden">
          <div className="h-full flex flex-col p-6">
            <div>
              <h4>{preApprovalRequest.program.name}</h4>
              <p className="body3 text-color-body1">{preApprovalRequest.program.description}</p>
            </div>
            <hr className="my-6" />
            {/*TODO (COMPT-5766): Implement program activity section*/}
            {/*<div>*/}
            {/*  <div className="flex flex-row gap-x-2 items-center">*/}
            {/*    <ComptSvgIcon iconName="program-activity-icon" />*/}
            {/*    <h4>Program activity</h4>*/}
            {/*  </div>*/}
            {/*  <p className="body3 text-color-body1">{preApprovalRequest.program.description}</p>*/}
            {/*</div>*/}
            {/*<hr className="my-6" />*/}
            <div>
              <div className="flex flex-row gap-x-2 items-center mb-4">
                <ComptSvgIcon iconName="message-text-icon" />
                <h4>Request form</h4>
              </div>
              <div className="flex flex-row">
                <div className="flex flex-col gap-y-2 items-start mb-4 basis-44">
                  <span className="text-color-body1">
                    <p className="label3">Items</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">Vendor</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">Amount</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">Expected completion date</p>
                  </span>
                  <span className="w-full pb-2">
                    <p className="body2">Description and reasons</p>
                  </span>
                </div>
                <div className="flex flex-col gap-y-2 items-start mb-4 basis-72">
                  <span className="text-color-body1">
                    <p className="label3">Selection</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">{preApprovalRequest.request_data['Vendor name']}</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">
                      {formatCurrencyFromCountryCode(
                        preApprovalRequest.request_data['Amount'],
                        'US',
                      )}
                    </p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">
                      {preApprovalRequest.request_data['Expected completion date']}
                    </p>
                    <br />
                  </span>
                  <span className="w-full pb-2">
                    <p className="body2">
                      {preApprovalRequest.request_data['Description and reason']}
                    </p>
                    <br />
                  </span>
                </div>
              </div>
              <hr className="mb-6" />
              {preApprovalRequest.expense && (
                <>
                  <div className="flex flex-row gap-x-2 items-center mb-4">
                    <ComptSvgIcon iconName="message-text-icon" />
                    <h4>Request form</h4>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-col gap-y-2 items-start mb-4 basis-44">
                      <span className="text-color-body1">
                        <p className="label3">Items</p>
                      </span>
                      <span className="border-b w-full pb-2">
                        <p className="body2">Vendor</p>
                      </span>
                      <span className="border-b w-full pb-2">
                        <p className="body2">Amount</p>
                      </span>
                      <span className="border-b w-full pb-2">
                        <p className="body2">Date of expense</p>
                      </span>
                      <span className="w-full pb-2">
                        <p className="body2">Description and reasons</p>
                      </span>
                    </div>
                    <div className="flex flex-col gap-y-2 items-start mb-4 basis-72">
                      <span className="text-color-body1">
                        <p className="label3">Selection</p>
                      </span>
                      <span className="border-b w-full pb-2">
                        <p className="body2">{preApprovalRequest.expense.vendor_name}</p>
                      </span>
                      <span className="border-b w-full pb-2">
                        <p className="body2">
                          {formatCurrencyFromCountryCode(
                            preApprovalRequest.expense.amount_of_expense,
                            'US',
                          )}
                        </p>
                      </span>
                      <span className="border-b w-full pb-2">
                        <p className="body2">{`${preApprovalRequest.expense.date_of_expense}`}</p>
                      </span>
                      <span className="w-full pb-2">
                        <p className="body2">{preApprovalRequest.expense.description}</p>
                        <br />
                      </span>
                    </div>
                  </div>
                  <hr className="mb-6" />
                </>
              )}
              <div>
                <div className="flex flex-row gap-x-2 items-center">
                  <ComptSvgIcon iconName="upload-cloud-icon" />
                  <h4>Request form documents</h4>
                </div>
                <div>
                  <ComptLink link={preApprovalRequestReceiptUrl} className="mb-2.5" download newTab>
                    Download pre-approval request document
                  </ComptLink>
                  {preApprovalRequest.expense && (
                    <ComptLink
                      link={preApprovalRequest.expense.receipt_image}
                      className="mb-2.5"
                      download
                      newTab
                    >
                      Download reimbursement request document
                    </ComptLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ComptSidePanel.Content>
      </ComptSidePanel>
    </>
  );
};
