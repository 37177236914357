import React from 'react';
import {ComptCard} from '@compt/common/compt-card/compt-card';

interface VendorCardProps {
  logoSrc: string;
  title: string;
  description: string;
  avatars: string[];
}

export const VendorCard = ({logoSrc, title, description, avatars}: VendorCardProps) => (
  <ComptCard>
    <div className="flex flex-col items-center p-4 px-6 text-center gap-4">
      <div
        className="absolute w-[128px] h-[128px] flex justify-center items-center bg-white 
  left-1/2 top-[-24px] transform -translate-x-1/2 rounded-xl shadow-[0px_6px_20px_0px_rgba(0,0,0,0.08)]"
      >
        <img src={logoSrc} alt={title} className="w-full h-full rounded-xl" />
      </div>

      <div className="flex flex-col items-center gap-2 max-w-[198px] mt-[112px]">
        <h3 className="text-xl font-semibold text-gray-800 mb-1">{title}</h3>
        <p className="text-md text-gray-600">{description}</p>
        <div className="flex -space-x-2">
          {avatars.map((avatar, index) => (
            <img
              key={index}
              src={avatar}
              alt="User Avatar"
              className="w-8 h-8 border-2 border-white rounded-full"
            />
          ))}
        </div>
      </div>
    </div>
  </ComptCard>
);
