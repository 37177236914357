export const MAX_ITEMS_PER_PAGE = 100;

export const MAX_BUSINESS_EXPENSE_REPORTS_PER_PAGE = 15;

export const MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE = 100;

export const REPORT_ID_PREFIX = 'EXP';

export const MAX_BUSINESS_EXPENSES_PER_PAGE = 15;

export const MAX_15_PAGE_LIMIT = 15;

export const DEFAULT_REIMBURSEMENT_INFO = `You can typically expect to be reimbursed for an expense
   that you submit in the month following your submission.`;

export enum ACTIVITY_FEED_FILTER_OPTION_LABEL {
  COMPANY_FEED = 'Company Feed',
  COUNTRY = 'My Country',
  DEPARTMENT = 'My Department',
}

export const DEFAULT_CHAR_FIELD_MAX_LENGTH = 255;
