import React, {useState, useEffect} from 'react';
import {VendorCard} from './vendor-card';
import {ComptPage} from '@compt/common/compt-page/compt-page';

// TO-DO: Replace with real data from the API in COMPT-5689
export const generateDummyCardData = (count = 100) => {
  const amazonData = {
    logoSrc: '/static/img/cover-squiggles.png',
    title: 'Amazon',
    description: 'Shop from a variety of deals and offers',
    avatars: [
      'https://randomuser.me/api/portraits/men/45.jpg',
      'https://randomuser.me/api/portraits/women/47.jpg',
    ],
  };

  const traderJoesData = {
    logoSrc: '/static/img/doodle-crown.png',
    title: "Trader Joe's",
    description: 'Learn, develop, or start a new skill',
    avatars: [
      'https://randomuser.me/api/portraits/men/32.jpg',
      'https://randomuser.me/api/portraits/women/44.jpg',
    ],
  };

  const cardData = [];

  for (let i = 0; i < count; i++) {
    if (i % 2 === 0) {
      cardData.push(traderJoesData);
    } else {
      cardData.push(amazonData);
    }
  }

  return cardData;
};

export const VendorExplorePage = () => {
  const [cardData, setCardData] = useState(generateDummyCardData(12));
  const [loading, setLoading] = useState(false);

  // TO-DO: Implement infinite scroll after API in COMPT-5689
  const loadMoreCards = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      const newCardData = generateDummyCardData(12);
      setCardData((prevData) => [...prevData, ...newCardData]);
      setLoading(false);
    }, 1000);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100 &&
      !loading
    ) {
      loadMoreCards();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    if (window.innerHeight >= document.documentElement.scrollHeight) {
      loadMoreCards();
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading]);

  return (
    <ComptPage
      title="Explore"
      subtitle="Shop vendors that are covered by your stipends"
      className="h-full"
      includeBottomHR={false}
      bottomHRClassName="mb-0 mt-400 sm:mt-000"
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {cardData.length &&
          cardData.map((card, index) => (
            <div key={index} className="relative mt-16">
              <VendorCard
                logoSrc={card.logoSrc}
                title={card.title}
                description={card.description}
                avatars={card.avatars}
              />
            </div>
          ))}
        {loading && <p className="col-span-full text-center">Loading more cards...</p>}
      </div>
    </ComptPage>
  );
};
